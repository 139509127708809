<template>
<v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
    <v-row justify="center" align="center">
        <v-col cols="12" md="8" lg="6">
            <v-row no-gutters justify="center" align="center">
                <v-col cols="6">
                    <v-img src='@/assets/logo/petmeetly-text-color.png'></v-img>
                </v-col>
            </v-row>

            <v-row justify="center" align="center">
                <v-col cols="11"  class="pb-5">
                    <h2>Get Started!</h2>
                </v-col>
            </v-row>
            <v-form ref="signupForm" class="signup" v-model="valid" lazy-validation @submit.prevent="signup">
                <v-row justify="center" align="center">
                    <v-col cols="11" class="pb-0">
                        <v-text-field flat solo background-color="#f3f3f3" label="Name" v-model="name" prepend-inner-icon="$vuetify.icons.account-icon" :rules="nameRules"></v-text-field>
                        <v-text-field flat solo background-color="#f3f3f3" label="Email" v-model="email" prepend-inner-icon="mdi-email-outline" :rules="emailRules" @change="checkEmailExists" :loading="emailValidating"></v-text-field>
                        <v-text-field flat solo background-color="#f3f3f3" label="Password" type="password" v-model="password" prepend-inner-icon="mdi-lock-outline" :rules="passwordRules"></v-text-field>
                        <v-btn large block color="primary" type="submit" :loading="submitting" :disabled="submitting">Sign up</v-btn>
                    </v-col>
                </v-row>
            </v-form>

            <v-row justify="center" align="center" class="pt-3">

                <v-col cols="10" class="text-center">
                    <span class="text-caption grey--text"> or get started with  </span>
                </v-col>

            </v-row>

            <v-row justify="space-around" align="center" class="px-12 pb-4">

                <v-btn fab class="btn-soft-shadow pa-4" color="white" style="display:inline-block" @click="googleAuth">
                    <v-img src="@/assets/img/google.png" contain></v-img>
                </v-btn>

                <v-btn fab class="btn-soft-shadow pa-4" color="white" style="display:inline-block" @click="facebookAuth">
                    <v-img src="@/assets/img/facebook.png" contain></v-img>
                </v-btn>

                <!--  <v-btn fab class="btn-soft-shadow pa-3" color="white" style="display:inline-block" @click="twitterAuth">
                    <v-img src="@/assets/img/twitter.png" contain></v-img>
                </v-btn>
 -->
            </v-row>

            <v-row justify="center" align="center">
                <v-col cols="10" class="pt-2 text-center">
                    Already have an account? <router-link :to="'/login'">Sign in</router-link>
                </v-col>
            </v-row>
        </v-col>
    </v-row>

    <v-snackbar v-model="snackbar">
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

</v-container>
</template>

<script>
import {
    auth
} from "../../plugins/firebase"
import {
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    TwitterAuthProvider
} from "firebase/auth";

export default {
    name: 'Signup',

    mounted() {
        if (this.$store.getters.isLoggedIn) {
            this.$router.replace('/home');
        }
    },

    data() {
        return {
            name: '',
            email: '',
            password: '',

            valid: true,
            submitting: false,
            emailValidating: false,

            snackbar: false,
            snackbar_text: '',

            nameRules: [
                v => !!v || 'Name is required',
                v => !(/@/.test(v)) || 'Name must be valid',
            ],

            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+/.test(v) || 'Email must be valid',
            ],

            passwordRules: [
                v => !!v || 'Password is required',
                v => (v || '').length >= 6 || 'Password must be minimum of 6 characters',
            ],
        }
    },

    methods: {

        signup() {
            if (this.$refs.signupForm.validate()) {
                this.submitting = true;
                let formData = new FormData();

                formData.append('password', this.password);
                formData.append('name', this.name);
                formData.append('email', this.email);

                this.$store.dispatch('signup', formData)
                    .then((resp) => {
                        this.submitting = false;
                        //console.log(resp)
                        //this.$router.push('/home');

                        window.gtag('set', 'user_data', {"email": this.email});
                        window.gtag('event', 'sign_up');
                        window.gtag('event', 'conversion', {'send_to': 'AW-10848052375/hWe7CLrLkKYDEJfJ4LQo'});

                        window.fbq('track', 'CompleteRegistration');

                        window.twq('event', 'tw-ocimw-ocirt', {
                                email_address: this.email
                            });

                        window.pintrk('track', 'signup');
                        window.rdt('track', 'SignUp');

                        //window.profitwell('start', { 'user_email': this.email });
                        window.profitwell('user_email', this.email);

                    })
                    .catch(err => {

                        //console.log(err)
                        this.snackbar_text = "Oops, We encountered an error while signing up. Please try again.";
                        this.snackbar = true;
                        this.submitting = false;
                    });

            }
        },

        checkEmailExists() {
            if (this.email.length > 0) {
                this.emailValidating = true;
                this.$store.dispatch('checkEmailExists', this.email)
                    .then((resp) => {
                        if (resp.data.message == 'User Already Exists') {
                            this.snackbar_text = "Oops, Email address is already registered. Try a different email address.";
                            this.snackbar = true;
                            this.email = '';
                        }
                        this.emailValidating = false;
                    })
                    .catch(err => {
                        //console.log(err);
                        this.emailValidating = false;
                    });
            }

        },

        googleAuth() {

            this.submitting = true;

            const provider = new GoogleAuthProvider();

            signInWithPopup(auth, provider).then((result) => {

                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;

                let name = user.displayName;
                let email = user.email;
                let phone = user.phoneNumber;
                let photo = user.photoURL;
                let service = 'Google'

                this.$store.dispatch('socialLogin', {name,email,phone,photo,service,token})
                    .then((resp) => {
                        this.submitting = false;
                        //console.log(resp)
                        //this.$router.push('/home');

                        if(resp.new == 1)
                        {
                            window.gtag('set', 'user_data', {"email": user.email});
                            window.gtag('event', 'sign_up');
                            window.gtag('event', 'conversion', {'send_to': 'AW-10848052375/hWe7CLrLkKYDEJfJ4LQo'});
                            window.fbq('track', 'CompleteRegistration');
                            window.twq('event', 'tw-ocimw-ocirt', {
                                email_address: user.email
                            });
                            window.pintrk('track', 'signup');
                            window.rdt('track', 'SignUp');
                        }

                        window.profitwell('start', { 'user_email': user.email });
                        //window.profitwell('user_email', user.email);

                    })
                    .catch(err => {
                        //console.log(err)
                        this.snackbar_text = "Oops, We encountered an error while signing up. Please try again.";
                        this.snackbar = true;
                        this.submitting = false;
                    });

            }).catch(err => {
                //console.log(err);
                this.submitting = false;
            });

        },

        facebookAuth() {

          this.submitting = true;

            const provider = new FacebookAuthProvider();

            signInWithPopup(auth, provider).then((result) => {

                const credential = FacebookAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;

                let name = user.displayName;
                //let email = user.email;
                let email = user.email || (user.providerData.find(data => data.providerId === "facebook.com") || {}).email;
                let phone = user.phoneNumber;
                let photo = `${user.photoURL}?access_token=${token}`;
                let service = 'Facebook'

                this.$store.dispatch('socialLogin', {name,email,phone,photo,service,token})
                    .then((resp) => {
                        this.submitting = false;
                        //console.log(resp)
                        //this.$router.push('/home');

                        if(resp.new == 1)
                        {
                            window.gtag('set', 'user_data', {"email": user.email});
                            window.gtag('event', 'sign_up');
                            window.gtag('event', 'conversion', {'send_to': 'AW-10848052375/hWe7CLrLkKYDEJfJ4LQo'});
                            window.fbq('track', 'CompleteRegistration');
                            window.twq('event', 'tw-ocimw-ocirt', {
                                email_address: user.email
                            });
                            window.pintrk('track', 'signup');
                            window.rdt('track', 'SignUp');
                        }
                        
                        window.profitwell('start', { 'user_email': user.email });
                        //window.profitwell('user_email', user.email);


                    })
                    .catch(err => {
                        //console.log(err)
                        this.snackbar_text = "Oops, We encountered an error while signing up. Please try again.";
                        this.snackbar = true;
                        this.submitting = false;
                    });

            }).catch(err => {
                //console.log(err);
                if (err.code === 'auth/account-exists-with-different-credential') {
                    this.snackbar_text = "It looks like you used Google to sign in earlier. Please try signing in with Google again.";
                    this.snackbar = true;
                }
                this.submitting = false;
            });

        },

        twitterAuth() {

            const provider = new TwitterAuthProvider();

            signInWithPopup(auth, provider).then((result) => {

                const credential = TwitterAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                //const secret = credential.secret;
                const user = result.user;

                //console.log(token);
                //console.log(user);

            }).catch(err => {
                //console.log(err);
            });

        }
    }
}
</script>
