<template>
<v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
    <v-row justify="center" align="center">
        <v-col cols="12" md="8" lg="6">
            <v-row no-gutters justify="center" align="center">
                <v-col cols="6" class="pb-16">
                    <v-img src='@/assets/logo/petmeetly-text-color.png'></v-img>
                </v-col>
            </v-row>

            <div v-if="submitting">
                <v-row>
                    <v-col cols="12" justify="center" align="center" class="mt-5">
                        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                    </v-col>
                </v-row>
            </div>
            <div v-else>
                <div v-if="updated > 0">
                    <div class="text-center text-h5 pb-12">
                        Thank You!
                    </div>
                    <div class="text-center">
                        You have been successfully removed from this email list and won't receive any further emails from us.
                    </div>
                </div>
                <div v-else>
                    <div class="text-center text-h5 pb-12">
                        We are Sorry!
                    </div>
                    <div class="text-center">
                        We don't recognize your email address in our email list. Sorry for the inconvenience.
                    </div>
                </div>
            </div>

        </v-col>
    </v-row>

</v-container>
</template>

<script>
export default {
    name: 'Unsubscribe',
    props: ['email'],

    mounted() {
        this.unSubscribe();
    },

    data() {
        return {
            submitting: false,
            updated: 0,

        }
    },

    methods: {

        unSubscribe() {

            this.submitting = true;

            this.$store.dispatch('unSubscribeProspect', this.email)
                .then((resp) => {
                    //console.log(resp);
                    this.updated = resp.updated;
                    this.submitting = false;

                    window.gtag('event', 'prospect_unsubscribed', {
                        event_category: 'Prospect',
                    });

                })
                .catch(err => {
                    this.submitting = false;
                });
        }

    },

}
</script>
