<template>
<v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
    <v-row justify="center" align="center">
        <v-col cols="12" md="8" lg="6">
            <v-row no-gutters justify="center" align="center">
                <v-col cols="6" class="pb-16">
                    <v-img src='@/assets/logo/petmeetly-text-color.png'></v-img>
                </v-col>
            </v-row>

            <div v-show="step==1">

        
            <v-form ref="emailform" v-model="valid" lazy-validation @submit.prevent="validateEmail">
                <v-row justify="center" align="center">
                    <v-col cols="11">
                        <div class="text-center pb-6">Enter your registered email address</div>
                        <v-text-field flat solo background-color="#f3f3f3" v-model="email" label="Email" prepend-inner-icon="$vuetify.icons.account-icon" :rules="emailRules"></v-text-field>
                        <v-btn large block color="primary" type="submit" :loading="submitting" :disabled="submitting">Next</v-btn>
                    </v-col>
                </v-row>
            </v-form>

            </div>
            <div v-show="step==2">

                <v-form ref="validationform" v-model="valid" lazy-validation @submit.prevent="validateCode">
                    <v-row justify="center" align="center">
                        <v-col cols="11">
                            <div class="text-center pb-6">Enter the verification code sent to your email address</div>
                             <v-otp-input class="pb-6" length="6" type="number" hide-spin-buttons @finish="validateCode" v-model="validation_code"></v-otp-input>
                            <!-- <v-text-field flat solo background-color="#f3f3f3" v-model="validation_code" label="Verification Code" prepend-inner-icon="mdi-key-outline" :rules="validationCodeRules"></v-text-field> -->
                            <v-btn large block color="primary" type="submit" :loading="submitting" :disabled="submitting">Validate</v-btn>
                        </v-col>
                    </v-row>
                </v-form>

            </div>

            <!-- <div v-show="step==3">

                <v-form ref="passwordform" v-model="valid" lazy-validation @submit.prevent="resetPassword">
                    <v-row justify="center" align="center">
                        <v-col cols="11">
                            <div class="text-center pb-4">Enter the verification code sent to your email address</div>
                            <v-text-field flat solo background-color="#f3f3f3" type="password" v-model="password" label="Password" prepend-inner-icon="mdi-lock-outline" :rules="passwordRules"></v-text-field>
                            <v-btn large block color="primary" type="submit" :loading="submitting" :disabled="submitting">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-form>

            </div> -->



        </v-col>
    </v-row>

    <v-snackbar v-model="snackbar">
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

</v-container>
</template>

<script>
import Bugsnag from '@bugsnag/js';

export default {
    name: 'ForgotPassword',

    data() {
        return {
            step: 1,
            email: '',
            password: '',
            submitting: false,
            validation_code:'',
            
            valid: true,
            snackbar: false,
            snackbar_text: '',

            passwordRules: [
                v => !!v ||  'Password is required',
                v => (v || '').length >= 6 || 'Password must have minimum 6 characters',
            ],
            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+/.test(v) || 'Email must be valid',
            ],

            validationCodeRules: [
                v => !!v ||  'Verification code is required',
                v => (v || '').length == 6 || 'Code must be 6 digits',
                v => /^\d*$/.test(v) || 'Code must be numeric'
            ],


        }
    },

     methods: {

        validateEmail() {

            if (this.$refs.emailform.validate()) {

                this.submitting = true;

                let email = this.email

                 this.$store.dispatch('forgotPassEmailValidate', {
                        email,
                    })
                    .then((resp) => {
                        this.submitting = false;
                        this.step = 2;
                    })
                    .catch(err => {
                        this.snackbar_text = 'Email is not registered with us';
                        this.snackbar = true;
                        this.submitting = false;
                    }); 
            }

        },

        validateCode(otp)
        {
             if (this.$refs.validationform.validate()) {

                this.submitting = true;

                let email = this.email;
                let validation_code = '';

                if (isNaN(otp)) {
                    validation_code = this.validation_code;
                } else {
                    validation_code = otp;
                }

                this.$store.dispatch('forgotPassCodeValidate', {
                        email,validation_code
                    })
                    .then((resp) => {
                        this.submitting = false;
                        this.$router.replace('/reset-password');
                        //this.step = 3;
                    })
                    .catch(err => {
                        Bugsnag.notify(err);
                        this.snackbar_text = 'Invalid Verification Code';
                        this.snackbar = true;
                        this.submitting = false;
                    }); 
             }

        },

        resetPassword()
        {
             if (this.$refs.passwordform.validate()) {

                this.submitting = true;

                let password = this.password;

                 this.$store.dispatch('forgotPassResetPassword', {
                        password
                    })
                    .then((resp) => {
                        this.submitting = false;
                        this.$router.push('/home');
                    })
                    .catch(err => {
                        this.snackbar_text = 'Password Reset Failed';
                        this.snackbar = true;
                        this.submitting = false;
                    }); 
             }
             

        }

      

    }
}
</script>
