<template>
    <v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
        <v-row justify="center" align="center">
            <v-col cols="12" md="8" lg="6">
                <v-row no-gutters justify="center" align="center">
                    <v-col cols="6" class="pb-8">
                        <v-img src='@/assets/logo/petmeetly-text-color.png'></v-img>
                    </v-col>
                </v-row>

                <div v-if="accountValid == 'inactive'">
                    <div class="text-center text-subtitle-1 pb-8 px-4">
                        <v-icon size="80" color="grey lighten-2">mdi-emoticon-sad-outline</v-icon>
                    </div>

                    <div class="text-center text-subtitle-1 pb-8 px-4">
                        Account does not exist or Inactive
                    </div>
                </div>

                <div v-if="accountValid == 'active'">
                    <div class="text-center text-subtitle-1 pb-8 px-4">
                        How do you want to manage our email notifications?
                    </div>

                    <div v-if="submitting">
                        <v-row>
                            <v-col cols="12" justify="center" align="center" class="mt-5">
                                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-else>

                        <v-row>
                            <v-col justify="center" align="center">
                                <v-sheet v-ripple="{ center: true, class: 'primary--text' }"
                                    @click="updateNotification('weekly')" ripple height="160" width="210"
                                    class="pa-2 px-3 rounded-lg mild-shadow clickable">
                                    <div class="text-center pa-3">
                                        <v-icon size="36" color="primary">mdi-calendar-clock</v-icon>
                                    </div>
                                    <div class="text-center text-caption">
                                        I dont have time to check the notifications daily. Send them weekly.
                                    </div>
                                </v-sheet>
                            </v-col>

                            <v-col justify="center" align="center">

                                <v-sheet v-ripple="{ center: true, class: 'orange--text' }"
                                    @click="updateNotification('off')" height="160" width="210"
                                    class="pa-2 px-3 rounded-lg mild-shadow clickable">
                                    <div class="text-center pa-3">
                                        <v-icon size="36" color="deep-orange">mdi-email-off-outline</v-icon>
                                    </div>
                                    <div class="text-center text-caption">
                                        I dont want any match notifications. Just let me know when someone messages me
                                    </div>
                                </v-sheet>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col justify="center" align="center">
                                <v-btn text small color="grey" to="/account">Delete My Account</v-btn>
                                <!-- <v-btn text small color="grey" @click="deleteAccountDialog = true">Delete My Account</v-btn> -->
                            </v-col>
                        </v-row>



                    </div>

                </div>



                <v-dialog v-model="deleteAccountDialog" persistent max-width="450" :retain-focus="false">

                    <v-card>

                        <div v-show="deleteStep == 1">
                            <v-card-text>
                                <div class="text-subtitle-1 font-weight-bold py-4">
                                    Are you sure you want to delete your account?
                                </div>

                                <div class="py-2">
                                    <ul>
                                        <li>All your pet profiles will be disabled.</li>
                                        <li>Your saved pet preferences will be disabled.</li>
                                        <li>Your active subscriptions will be cancelled immediately.</li>
                                        <li>Your direct connect balance will be cancelled.</li>
                                    </ul>
                                </div>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn depressed @click="deleteAccountDialog = false;" color="secondary">
                                    No
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn depressed @click="deleteStep = 2;">
                                    Yes
                                </v-btn>
                            </v-card-actions>
                        </div>

                        <div v-show="deleteStep == 2">

                            <v-toolbar dense flat color="primary" dark>
                                <v-toolbar-title class="text-subtitle-1">Feedback</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="deleteStep = 1; deleteAccountDialog = false;">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>

                            </v-toolbar>

                            <v-card-text>
                                <div class="text-subtitle-2 pb-4">
                                    Your account will be closed shortly. Hope you don't mind answering a couple of
                                    questions.
                                </div>
                                <v-divider></v-divider>
                                <div class="text-subtitle-2 pt-4 primary--text">
                                    Why do you want to delete your account?
                                </div>
                                <div class="pt-2">
                                    <v-radio-group v-model="deleteReason" class="mt-2">
                                        <v-radio label="Already found a pet/buyer"
                                            value="Already found a pet"></v-radio>
                                        <v-radio label="Couldn't find a pet/buyer"
                                            value="Couldn't find a pet"></v-radio>
                                        <v-radio label="Not looking for a pet/buyer"
                                            value="Not looking for a pet"></v-radio>
                                        <v-radio label="Other reason" value="Other reason"></v-radio>
                                    </v-radio-group>
                                </div>
                                <div v-show="deleteReason == 'Other reason'">
                                    <v-text-field class="pt-0" outlined dense v-model="otherReason"
                                        placeholder="Please let us know"></v-text-field>
                                </div>

                                <div v-show="deleteReason == 'Already found a pet'">

                                    <v-divider></v-divider>
                                    <div class="text-subtitle-2 pt-4 primary--text">
                                        Where did you find the pet/buyer?
                                    </div>
                                    <div class="pt-2">
                                        <v-radio-group v-model="matchSource" class="mt-2">
                                            <v-radio label="Petmeetly" value="Petmeetly"></v-radio>
                                            <v-radio label="Friends & Family" value="Friends & Family"></v-radio>
                                            <v-radio label="From other sources" value="From other sources"></v-radio>
                                        </v-radio-group>
                                    </div>
                                    <div v-show="matchSource == 'From other sources'">
                                        <v-text-field class="pt-0" outlined dense v-model="otherSource"
                                            placeholder="Care to share from where?"></v-text-field>
                                    </div>

                                </div>

                                <div v-show="matchSource == 'Petmeetly' && deleteReason == 'Already found a pet'">

                                    <v-divider></v-divider>
                                    <div class="text-subtitle-2 pt-4 primary--text">
                                        Will you recommend Petmeetly to your friends?
                                    </div>
                                    <div class="pt-2">
                                        <v-radio-group v-model="willRecommend" class="mt-2">
                                            <v-radio label="Yes" value="Yes"></v-radio>
                                            <v-radio label="No" value="No"></v-radio>
                                        </v-radio-group>
                                    </div>

                                    <div v-show="willRecommend == 'No'">
                                        <v-textarea dense outlined auto-grow row-height="30" rows="2"
                                            placeholder="Care to share why you won't?"
                                            v-model="willRecommendReason"></v-textarea>
                                    </div>

                                </div>

                                <v-divider></v-divider>
                                <div class="text-subtitle-2 py-4 primary--text">
                                    Anything that can be improved?
                                </div>
                                <v-textarea dense outlined auto-grow row-height="30" rows="2"
                                    placeholder="Please let us know your suggestions" v-model="feedback"></v-textarea>

                            </v-card-text>

                            <v-card-actions>

                                <v-btn depressed @click="deleteStep = 1; deleteAccountDialog = false;">
                                    Cancel
                                </v-btn>
                                <v-spacer></v-spacer>

                                <v-btn color="secondary" :loading="deleting"
                                    :disabled="deleting || !deleteReason || (deleteReason == 'Already found a pet' && !matchSource)"
                                    @click="deleteAccount">
                                    Submit
                                </v-btn>

                            </v-card-actions>

                        </div>

                    </v-card>

                </v-dialog>

            </v-col>
        </v-row>

        <v-snackbar v-model="snackbar">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

    </v-container>
</template>

<script>
export default {
    name: 'UnsubscribeEmails',
    props: ['xid'],

    mounted() {
        this.validateAccount();
    },

    data() {
        return {
            submitting: false,
            updated: 0,
            snackbar: false,
            snackbar_text: '',

            deleteAccountDialog: false,
            deleteStep: 1,
            deleteReason: '',
            otherReason: '',
            matchSource: '',
            otherSource: '',
            willRecommend: '',
            willRecommendReason: '',
            feedback: '',
            deleting: false,
            accountValid: '',

            unsubscribeCase: '',

        }
    },

    methods: {

        validateAccount() {

            this.submitting = true;

            this.$store.dispatch('checkAccountActive', this.xid)
                .then((resp) => {
                    if (resp.data.message == 'User Not Active') {
                        this.snackbar_text = "Account is not active";
                        this.snackbar = true;
                        this.accountValid = 'inactive';
                    }
                    else if (resp.data.message == 'User Active') {
                        this.accountValid = 'active';
                    }
                    this.submitting = false;
                })
                .catch(err => {
                    this.submitting = false;
                });

        },

        updateNotification(val) {

            this.submitting = true;

            let xid = this.xid;

            this.$store.dispatch('updateNotification', {
                xid,
                val
            })
                .then((resp) => {
                    //console.log(resp);

                    this.submitting = false;
                    this.snackbar_text = "Notification Settings Updated";
                    this.snackbar = true;

                })
                .catch(err => {
                    this.submitting = false;
                    this.snackbar_text = "Error while saving";
                    this.snackbar = true;
                });
        },

        deleteAccount() {

            this.deleting = true;
            let xid = this.xid;
            let deleteReason = this.deleteReason
            let otherReason = this.otherReason
            let matchSource = this.matchSource
            let otherSource = this.otherSource
            let willRecommend = this.willRecommend
            let willRecommendReason = this.willRecommendReason
            let feedback = this.feedback

            this.$store.dispatch('deleteAccountExt', {
                deleteReason,
                otherReason,
                matchSource,
                otherSource,
                willRecommend,
                willRecommendReason,
                feedback,
                xid
            })
                .then(() => {
                    this.deleting = false;
                    this.snackbar_text = "Account Suspended";
                    this.snackbar = true;
                    this.deleteAccountDialog = false;
                    this.accountValid = 'inactive';

                })
                .catch(err => {
                    this.snackbar_text = "Error while updating";
                    this.snackbar = true;
                    this.deleting = false;
                });

        },

    },

}
</script>
