<template>
<v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
    <v-row justify="center" align="center">
        <v-col cols="12" md="8" lg="6">
            <v-row no-gutters justify="center" align="center">
                <v-col cols="6">
                    <v-img src='@/assets/logo/petmeetly-text-color.png'></v-img>
                </v-col>
            </v-row>

            <v-row justify="center" align="center">
                <v-col cols="11" class="pb-6">
                    <h2>Welcome Back!</h2>
                </v-col>
            </v-row>
            <v-form ref="loginForm" v-model="valid" lazy-validation @submit.prevent="login">
                <v-row justify="center" align="center">
                    <v-col cols="11" class="pb-0">
                        <!-- <v-text-field placeholder="Email address" background-color="#f3f3f3" v-model="email"  prepend-inner-icon="mdi-account-outline" :rules="emailRules" filled rounded dense></v-text-field>
                         <v-text-field placeholder="Password" background-color="#f3f3f3" v-model="password" type="password"  prepend-inner-icon="mdi-lock-outline" :rules="passwordRules" append-icon="mdi-lifebuoy" @click:append="forgotPassword" filled rounded dense></v-text-field> -->
                        <v-text-field flat solo background-color="#f3f3f3" v-model="email" label="Email" prepend-inner-icon="$vuetify.icons.account-icon" :rules="emailRules"></v-text-field>
                        <v-text-field flat solo background-color="#f3f3f3" v-model="password" type="password" label="Password" prepend-inner-icon="mdi-lock-outline" :rules="passwordRules"></v-text-field>
                        <!-- <v-text-field flat solo background-color="#f3f3f3" v-model="password" type="password" label="Password" prepend-inner-icon="mdi-lock-outline" :rules="passwordRules" append-icon="mdi-lifebuoy" @click:append="forgotPassword"></v-text-field> -->
                        <div><v-btn text small color="primary" class="float-right mb-4 mt-n4" @click="forgotPassword">Forgot Password?</v-btn></div>
                        <v-btn large block color="primary" type="submit" :loading="submitting" :disabled="submitting">Login</v-btn>
                    </v-col>
                </v-row>
            </v-form>

            <v-row justify="center" align="center" class="pt-6">

                <v-col cols="10" class="text-center">
                    <span class="text-caption grey--text"> or continue with </span>
                </v-col>

            </v-row>

            <v-row justify="space-around" align="center" class="px-12 pb-4">

                <v-btn fab class="btn-soft-shadow pa-4" color="white" style="display:inline-block" @click="googleAuth">
                    <v-img src="@/assets/img/google.png" contain></v-img>
                </v-btn>

                <v-btn fab class="btn-soft-shadow pa-4" color="white" style="display:inline-block" @click="facebookAuth">
                    <v-img src="@/assets/img/facebook.png" contain></v-img>
                </v-btn>

                <!--  <v-btn fab class="btn-soft-shadow pa-3" color="white" style="display:inline-block" @click="twitterAuth">
                    <v-img src="@/assets/img/twitter.png" contain></v-img>
                </v-btn>
 -->
            </v-row>

            <v-row justify="center" align="center">
                <v-col cols="10" class="pt-2 text-center">
                    Dont have an account?
                </v-col>
            </v-row>
            <v-row justify="center" align="center" class="mt-0">
                <v-col cols="11" class="text-center">
                    <v-btn large block outlined color="primary" :to="'/signup'">Create an account</v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>

    <v-snackbar v-model="snackbar">
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

</v-container>
</template>

<script>
import {
    auth
} from "../../plugins/firebase"
import {
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    TwitterAuthProvider
} from "firebase/auth";

export default {
    name: 'Login',

    data() {
        return {

            email: '',
            password: '',

            valid: true,
            submitting: false,
            emailValidating: false,

            snackbar: false,
            snackbar_text: '',

            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+/.test(v) || 'Email must be valid',
            ],

            passwordRules: [
                v => !!v || 'Password is required',
            ],
        }
    },

    mounted() {
        if (this.$store.getters.isLoggedIn) {
            this.$router.replace('/home');
        }
    },

    methods: {

        forgotPassword() {
            this.$router.push('forgot-password')
        },

        login() {

            if (this.$refs.loginForm.validate()) {
                this.submitting = true;
                let email = this.email
                let password = this.password

                this.$store.dispatch('login', {
                        email,
                        password
                    })
                    .then(() => {
                        this.submitting = false;
                        //this.$router.push('/home');
                        window.profitwell('start', { 'user_email': this.email });
                        //window.profitwell('user_email', this.email);

                    })
                    .catch(err => {

                        if (typeof err.response.data.error != "undefined") {
                            this.snackbar_text = err.response.data.error;
                        } else {
                            this.snackbar_text = "Invalid Email/Password";
                        }

                        this.snackbar = true;
                        this.submitting = false;
                    });

            }
        },

        googleAuth() {

            this.submitting = true;

            const provider = new GoogleAuthProvider();

            signInWithPopup(auth, provider).then((result) => {

                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;

                let name = user.displayName;
                let email = user.email;
                let phone = user.phoneNumber;
                let photo = user.photoURL;
                let service = 'Google'

                this.$store.dispatch('socialLogin', {
                        name,
                        email,
                        phone,
                        photo,
                        service,
                        token
                    })
                    .then((resp) => {
                        this.submitting = false;
                        //console.log(resp)
                        //this.$router.push('/home');
                        if(resp.new == 1)
                        {
                            window.gtag('event', 'sign_up');
                            window.fbq('track', 'CompleteRegistration');
                            window.pintrk('track', 'signup');
                            window.rdt('track', 'SignUp');
                            window.twq('event', 'tw-ocimw-ocirt', {
                                email_address: user.email
                            });
                        }

                        window.profitwell('start', { 'user_email': user.email });
                        //window.profitwell('user_email', user.email);

                    })
                    .catch(err => {
                        //console.log(err)
                        this.snackbar_text = "Oops, We encountered an error while signing up. Please try again.";
                        this.snackbar = true;
                        this.submitting = false;
                    });

            }).catch(err => {
                //console.log(err);
                this.submitting = false;
            });

        },

        facebookAuth() {

            this.submitting = true;

            //const auth = getAuth();
            const provider = new FacebookAuthProvider();

            signInWithPopup(auth, provider).then((result) => {

                const credential = FacebookAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;

                //console.log("User details from Facebook:", user);

                let name = user.displayName;
                let email = user.email || (user.providerData.find(data => data.providerId === "facebook.com") || {}).email;
                let phone = user.phoneNumber;
                //let photo = user.photoURL;
                let photo = `${user.photoURL}?access_token=${token}`;
                let service = 'Facebook'

                this.$store.dispatch('socialLogin', {
                        name,
                        email,
                        phone,
                        photo,
                        service,
                        token
                    })
                    .then((resp) => {
                        this.submitting = false;
                        //console.log(resp)
                        //this.$router.push('/home');

                        if(resp.new == 1)
                        {
                            window.gtag('event', 'sign_up');
                            window.fbq('track', 'CompleteRegistration');
                            window.pintrk('track', 'signup');
                            window.rdt('track', 'SignUp');
                            window.twq('event', 'tw-ocimw-ocirt', {
                                email_address: user.email
                            });
                        }

                        window.profitwell('start', { 'user_email': user.email });
                        //window.profitwell('user_email', user.email);

                    })
                    .catch(err => {
                        //console.log(err)
                        this.snackbar_text = "Oops, We encountered an error while signing up. Please try again.";
                        this.snackbar = true;
                        this.submitting = false;
                    });

            }).catch(err => {
                //console.log(err);
                if (err.code === 'auth/account-exists-with-different-credential') {
                    this.snackbar_text = "It looks like you used Google to sign in earlier. Please try signing in with Google again.";
                    this.snackbar = true;
                }
                this.submitting = false;
            });

        },

        twitterAuth() {

            const provider = new TwitterAuthProvider();

            signInWithPopup(auth, provider).then((result) => {

                const credential = TwitterAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                //const secret = credential.secret;
                const user = result.user;

                //console.log(token);
                //console.log(user);

            }).catch(err => {
                //console.log(err);
            });

        }
    }
}
</script>
