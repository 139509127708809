<template>
    <v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
        <v-row justify="center" align="center">
            <v-col cols="12" md="8" lg="6">
                <v-row no-gutters justify="center" align="center">
                    <v-col cols="6" class="pb-16">
                        <v-img src='@/assets/logo/petmeetly-text-color.png'></v-img>
                    </v-col>
                </v-row>

                <div>
                    <v-row>
                        <v-col cols="12" justify="center" align="center" class="mt-5"  v-if="submitting">
                            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                        </v-col>
                        <v-col cols="12" justify="center" align="center" class="mt-5">
                            {{ this.notice }}
                        </v-col>
                        <v-col cols="12" justify="center" align="center" class="mt-5"  v-if="!submitting">
                            <v-btn color="primary" :to="'/home'">
                                Home
                            </v-btn>
                        </v-col>

                    </v-row>
                </div>
                

            </v-col>
        </v-row>

    </v-container>
</template>

<script>
export default {
    name: 'QuickReply',


    mounted() {

        this.quickReply();
    },

    data() {
        return {
            submitting: false,
            notice: '',

        }
    },

    methods: {

        quickReply() {

            this.submitting = true;
            this.notice = "Sending Message";

            let cxid = this.$route.query.c
            let fxid = this.$route.query.f
            let txid = this.$route.query.t
            let message = this.$route.query.m

            if(message == 'not-interested')
            {
                message = 'Sorry, I\'m not interested.'
            }

            this.$store.dispatch('sendQuickReply', {cxid,fxid,txid,message})
                .then((resp) => {
                    //console.log(resp);
                    this.submitting = false;
                    this.notice = "Message Sent";


                })
                .catch(err => {
                    this.submitting = false;
                    this.notice = "Error Sending Message. Please retry on Petmeetly";
                });
        }

    },

}
</script>
